.bd-context {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: 'Telegraf', sans-serif;
  gap: 5rem;
  padding: 30px; 
  background-color:#FFFBF1;
}

.head-txt h1 {
  margin-top:10px;
  font-weight:700;
  font-size: 30px
}

.board-member img {
  width: auto;
  height: 250px;
  margin: 0 auto;
}

.members-box {
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


@media screen and (max-width:767px){
  .head-txt h1 {
    margin-top:5px;
    font-weight:500;
    font-size:25px
  }

  .board-member img {
    width: auto;
    height: 180px;
    margin: 0 auto;
  }
  .bd-context{
    gap: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px){

  .members-box {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .board-member img {
    width: auto;
    height: 200px;
    margin: 0 auto;
  }

}
