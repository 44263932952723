.gif  {
    position: absolute;
    margin-top: -6rem;
}

.group-1{
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #FFFBF1;
    
}

.heading-1{
    margin: 5rem 0rem 0rem 4rem;
    z-index: 1;
}

.group-1 Button{
    margin-top: 3rem;                                              
}
.group1-button li{
    list-style: none;
}
  
.content-1{
    width:810px;
    height: 216px;
    font-size:38px;
    font-weight: 700;
    line-height: 65px;
    text-align: left;
}
  
.content-2{
    width:250px;
    height: 111px;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    color: #0F4C5C;
}
  
.group1-button{
    margin:5rem 0rem 0rem 10px;
}
  
.line-1{
    width: 20%;
    height: 2%;
    border: none;
    background-color: rgb(134, 6, 6);
    margin-bottom: 1rem;
}
  
.heading-2{
    margin:10rem 0 0 11rem;
    z-index: 1;
}

.group-2{
    background-color: #FFFBF1;
}
.core-values-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
}
  
.core-value {
    border:none;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #FFFBF1;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight:400;
    line-height: 15px;
    padding-right:5px ;
}
  
.core-heading {
    font-weight: bold;
    font-size: 1.2em;
    color: black;
    display: block;
    margin-bottom: 5px;
}
  

.group-2-slide{
    display: flex;
}

.control {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    margin:2rem 0 1rem 3rem
}
  
.previous button,
.next button {
    background-color: black;
    padding:5px 15px;
    border: 2px solid black;
    border-radius: 20px;
    width: 112px;
    height: 40px;
    color: white;
}
.previous button:hover,
.next button:hover{
    border: 2px solid black;
    border-radius: 20px;
    color: black;
}

.group-2-slide img {
    width: 965px;
    height: 472px;
    object-fit:cover;
    margin-top: 7rem;
    
}

.slide-info {
    position: absolute;
    justify-self: center;
    z-index: 1;
    width: 566px;
    height:auto;
    top: calc(300px - 70%); 
    padding: 40px 54px;
    border:none;
    color: white;
    margin-left:9rem;
    margin-top: 7rem;

}

.slide-info h1 {
    margin: 0.5rem 0 2rem 0;
    font-family: 'Telegraf', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
}

.slide-info p {
    font-family: 'Roboto Light', sans-serif;
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
}

.description {
    white-space: pre-line;
}

  
.bold-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; 
    width: 100%;
    height: 380px;
    background-color: #FFFBF1;
}
  
.bold-text p {
    font-family: "Base Neue" sans-serif;
    width: 828px;
    font-size: 48px;
    font-weight: 400;
    line-height: 56.54px;
    margin:3rem 0;
}




@media screen and (max-width:767px) {
    .group-1{
        gap: 0;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }
    .heading-2{
        display: none;
        width: 0;
        height: 0;
    }

    .group1-button{
        display: none;
    }
    
    .heading-1{
        width:360px;
        height: auto;
        margin-left:2rem;
        padding: 0;
    }
       
    .heading-1 .content-1{
        width: 350px;
        font-family: 'Poppins' sans-serif;
        font-size:23px;
        font-weight: 700;
        line-height: 35px;
        text-align:left;
        padding: 1rem 0;
    }
     
    .gif{
        width: 500px;
        margin-left: -16rem;
        margin-top: 14rem;
        padding: 0;
        scale: 1.5;
    }

    .group-2{
        margin: 0;
        padding:2rem 0 0 2rem;
    }
    .group-2 .control{
        display: none;
    }

    .group-2-slide{
       margin-top: 1rem;
    }

    .group-2-slide img {
        width: 360px;
        height: 250px;
        margin-top:8rem;
    }

    .slide-info {
        position: absolute;
        z-index: 1;
        justify-self: center;
        width:280px;
        height: auto; 
        top: calc(100px - 50%); 
        padding:20px;
        margin-left:2rem;
        text-align: center;
    }

    .slide-info h1{
        width: 230px;
        font-size: 20px;
        font-weight: 600;
        line-height: 22.5px;
    }

    .slide-info p{
        width: 250px;
        font-size: 17px;
        font-weight: 400;
        line-height: 22px;
        text-align:justify;
        margin: 0;
        
    }

    .bold-text{
        height: 200px;
        padding:1rem;
    }

    .bold-text p{
        font-size: 30px;
        font-weight: 400;
        line-height: 37.7px;    
        text-align: center;
    }
}


@media screen and (min-width:768px) and (max-width:1024px){
   .gif{
    margin-top: -3rem;
   }

   .heading-2{
    display: none;
   }
   
    .group-2-slide{
        display: flex;
        margin-left:2rem;
    }
    
    .control {
        display:none;
    }
      
    .group-2-slide img {
        width: 800px;
        height: 472px;
        margin-top: 6rem;
        
    }
    
    .slide-info {
        position: absolute;
        justify-self: center;
        z-index: 1;
        width: 500px;
        height:auto;
        top: calc(250px - 50%); 
        padding: 20px 24px;
        border:none;
        color: white;
        margin-left:8rem;
        margin-top: 4rem;
    }
    
    .slide-info h1 {
        margin: 0.5rem 0 1.5rem 0;
        font-size: 40px;
        font-weight: 600;
        line-height: 36px;
    }
    
    .slide-info p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    
   
}

@media screen and (max-width:420px) {
    .group-2-slide img {
        width:300px;
        margin-top:6rem;
    }
    .core-value {
        border: 1px solid #ffffff;
        padding:0;
        box-sizing: border-box;
        background-color: #FFFBF1;
        color: black;
        font-size: 10px;
    }
      
    .core-heading {
        font-weight: bold;
        font-size:15px;
        color: black;
        display: block;
        margin-bottom:2px;
    }

    .slide-info {
        position: absolute;
        z-index: 1;
        justify-self: center;
        width:220px;
        height:auto;
        top: calc(100px - 50%); 
        padding: 10px;
        text-align: center;
    }

    .slide-info h1{
        width: 190px;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.5px;
    }

    .slide-info p{
        width: 200px;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        text-align:center;
    }

    .bold-text p{
        font-size: 32px;
        font-weight: 400;
        line-height: 37.7px;    
        text-align: center;
        width: fit-content;
    }
}



