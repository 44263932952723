.group-7 {
    display:grid;
    grid-template-columns: 1fr 1fr;
    background: #D9D9D9;
    gap: 2rem;
    padding: 20px 0;
}

.group-7-img{
    scale: 0.46;
    width:350px;
    height:400px;
    margin-top: -4rem;
    margin-left: -50px;
}

.right-side {
    margin-top: 15px;
    margin-left: 15px;
}

.right-side .line-4 {
    width: 90px;
    height: 0px;
    border: 3px solid #9A031E;
    border-radius: 4px;
    margin-bottom: 15px;
}

.right-side h2 {
    width: 385px;
    height: 54px;
    font-family: 'Telegraf', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.88px;
    text-align: left;
}
.right-side .info p{
    padding-right: 9rem;
    margin-bottom:3rem;
    font-family: 'Roboto Light', sans-serif;
    font-weight: 300;
    line-height: 25px;
}

.group-7 .form{
    width: fit-content;
}

.group-7 .form input {
    display: flex;
    flex-direction: column;
    width:510px;
    height: 50px;
    background: transparent;
    border-radius: 0;
    border: 2px solid black; 
    margin-bottom: 20px;
    padding: 0 5px;
}

.group-7 .form input[type="text"]:focus {
    border: 2px solid #9A031E; 
    outline: none; 
}

.form .checkbox input {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
    border: 2px solid black;
}

.Name-section {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.form .Name-section input {
    width: 250px;
    padding: 0 5px;
}

.form .message input {
    height: 130px;
}

.submit button {
    padding: 10px 14rem;
    background-color: #9A031E;
    color: #ffff;
    border: none;
    margin-left: 0;
    margin-bottom: 15px;
}

.group-7-img2 img {
    display: none;
}

@media screen and (max-width: 767px) {
    .group-7 {
        display: flex;
        flex-direction: column;
        padding:20px;
        gap: 0;
        margin: 0;
    }

    .group-7-img  {
        display: none;
        width: 0px;
        height: 0px;
    }

    .group-7-img2 img {
        display: block;
        width: 306px;
        height: 304px;
        margin-top: 2rem;
    }

    .header {
        margin-top: 3rem;
    }

    .right-side .info p {
        padding-right:2rem;
        font-size: 16px;
        margin-top: 2rem;
        margin-bottom:0px;
        text-align: justify;
    }

    .right-side h2 {
        width: 250px;
    }

    .right-side .info{
        margin-bottom:1rem;
    }

    .group-7 .form {
        gap: 0;
        margin-top:5rem;
    }

    .group-7 .form input {
        margin-top: 0;
        width: 320px;
        gap: 0;
    }

    .Name-section {
        display: flex;
        flex-direction: column;
    }

    .message input {
        height: 120px;
    }

    button.submit {
        padding: 1rem 8rem;
    } 

    .checkbox input {
        width: 25px;
        margin-bottom: 10px;
        justify-items: left;
        margin-left: -9rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .group-7 {
        display: flex;
        flex-direction: column;
        padding:40px;
        gap: 0;
        margin: 0;
        height: auto;

    }

    .group-7-img  {
        display: none;
        width: 0px;
        height: 0px;
    }

    .group-7-img2 img {
        display: block;
        width: 506px;
        height: 350px;
        margin-top: 2rem;
    }

    .header {
        margin-top: 3rem;
    }

    .right-side p {
        font-size: 18px;
        font-weight: 400;
        margin-top: 2rem;
        width: 290px;
        padding-right: 0;
    }

    .right-side h2 {
        width: 250px;
    }

    .group-7 .form {
        gap: 0;
        margin-top: 2rem;
    }

    .group-7 .form input {
        margin-top: 0;
        width: 700px;
        gap: 0;
    }

    .Name-section {
        display: flex;
        flex-direction: column;
    }

    .message input {
        height: 150px;
    }

    .checkbox input {
        width: 25px;
    }
}
