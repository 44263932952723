@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');

.footer{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    margin: 2.5rem 2.5rem;
    gap: 15px;
}

.first-part, .second-part{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
}

.left-side-first{
    display: flex;
    flex-direction: row;
    gap:7px;
}

.footer .navbar-logo{
    margin:0;
    scale: 0.8;
}

.left-side-first .adress{
    margin-top: 10px;
    font-family: 'Roboto Light', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 19.5px;
    text-align: left;
    width: 324px;
}

.left-side-first .abuja{
    margin-top: 5px;
}

.first-part p{
    margin-top: -1rem;
    margin-bottom: 15px;
}

.right-side-first button {
    width: 200px;
    height: 55px;
    background: #9A031E;
    border: none;
    font-family: "Cabinet Grotesk" sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.8px;
    text-align: center;
    color: #FFFFFF;
}

.left-side-second ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    font-family: 'Cabinet Grotesk', sans-serif;
    margin-top:4rem;       
    gap: 5px;                                  
    
}

.nav-links-footer{
    color: black;
    text-decoration: none;
    font-size: 13px;
    padding: 1rem 0.5rem;
}

.nav-links-footer:hover {
    background-color:#9A031E;
    color: #FFFFFF;
    padding: 20px 10px;
    transition: all 0.2s ease-out;
}

.details{
    display: flex;
    flex-direction: row;
    gap: 18px;
}

.right-side-second p{
    font-family: 'Telegraf', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 26.82px;
    text-align: left;
    width: 447px;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 2rem;
}

.details input{
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 55px;
    background:transparent;
}
.details input[type="text"] {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid black; 
    border-radius: 0;

}

.details input[type="text"]:focus{
    border: 2px solid #9A031E; 
    outline: none; 
}

.details button{
    width: 145px;
    height: 55px;
    background: #020202;
    border: none;
    font-family: Cabinet Grotesk;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.8px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.details button:active {
    border: 2px solid black;
    background: transparent;
    color: black;
}

.left-side-first .line-6{
    border-left: 4px solid #9A031E;
    height:70px;
    margin:5px 0 0 0 ;
}

.line-7{
    border: 1px solid #9A031E;
    width: 100%;
}
.line-8{
    border: 1px solid #9A031E;
    width:100%;
    margin: 0;
}

.buttom {
    display:grid;
    grid-template-columns: 1fr 1fr;
    font-family: 'Telegraf', sans-serif;
    font-weight: 700;
}
.Link-word{
    text-decoration: none;
    color: #9A031E;
}

.social-media{
    margin-top:0;
}

.social-media ul{
    list-style: none;
    gap: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 40rem;
}

.right-side-first, .right-side-second{
    justify-self: right;
}


@media screen and (max-width:767px){
    .footer{
        width:fit-content;
        display: flex;
        justify-content: center;
        margin:0;
        padding:5px ;

    }

    .footer .navbar-logo{
        position: relative;
        margin-left:-1rem;
    }

    .left-side-second ul{
        display:flex;
        flex-direction: column;
        gap: 5px;
        margin-top:2rem;
    }
    
    .left-side-first .adress{
        width: 0;
    } 

    .right-side-first{
        margin-left: 2rem;
    }

    .social-media ul{
        margin-left:5px;
        gap: 2px;
        justify-items: right;
    }
    .right-side-second{
        margin: 2rem;
    }
    .right-side-second p{
        font-size: 15px;
        font-weight: 600;
        line-height: 16.82px;
        text-align: left;
        margin-top: 5px;
        margin-left: 0;
        margin-bottom:5px;
        width: 200px;
       
    }
    .details{
        display: flex;
        flex-direction: column;
        gap:0;
        margin: 0;
    }
    .details input{
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 40px;
        background:transparent;
    }
    .details button{
        width: 120px;
        height: 40px;
        font-size: 15px;
        font-weight: 500;
        line-height: 16.8px;
        margin: 0;
    }

    .first-part{
        display: flex;
        flex-direction: column;
        gap:2rem;
    }

    .second-part{
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    .first-part p{
        margin-top:1rem;
        padding-right: 1rem;
    }
    .left-side-first .line-6{
        margin-top: 3.5rem;
        margin-left:2rem;
        height:100px;
    }

    .nav-links-footer:hover {
        padding: 10px 5px;
        transition: all 0.2s ease-out;
    }
    .buttom{
        gap: 0;
        padding:0 5px;
    }

    .buttom p{
        font-size: small;
        width: 230px;
    }

    .social-media{
        margin-top: 10px;
    }
    
    .social-media img{
        width: 25px;
        height: 18px;
        margin-left:0;
    }

    .line-7{
        width:100%;
    }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .buttom p{
        width: 80%;
    } 

    .social-media{
        margin-top: 0;
    }

    .social-media ul{
        margin: 0;
    }
}