.group-3 {
    display: flex;
    flex-direction: column;
    background-color: #EAEAEA;
    padding: 20px 15px ;
    width: 100%;
    height:auto;
    font-family: 'Poppins' sans-serif;
}

.line-2 {
    width: 90px;
    height: 0;
    top: 1710px;
    left: 63px;
    border: 4px solid #9A031E;
    margin: 2rem 0 2rem 6rem;
}

.group-3 h2{
    width: 800px;
    height: 76px;
    font-family: 'Telegraf', sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;
    margin-left: 6rem;
}

.box-container{
    display: flex;
    flex-direction: column;
    margin:5rem;
}

.box-container img{
    width:60px;
    padding: 6px 0px 0px 0px;
}
.group-3 h3{
    width: 350px;
    font-family: 'Telegraf', sans-serif;
    font-size: 22px;
    font-weight: 800;
    line-height: 33px;
    text-align: left;
    margin: 20px 0;
    color: #9A031E;
}

.Read p{
    width: 330px;
    height: 220px;
    font-family: 'Roboto Light', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 22.5px;
    text-align:justify;
}

.group-3-control{
    display: flex;
    justify-content: right;
    cursor: pointer;
    gap: 20px;
}
  
.group-3-control button {
    width: 85px;
    height: 40px;
    border-radius: 20px;
    background-color: transparent;
    border: 2px solid black;
}
  
.group-3-control button:active{
    background-color:#9A031E ;
    border: none;
}

.group-3-line {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #ccc;
    margin-bottom: 20px;
}
  
.line-3 {
    width: 100%;
    height: 4px;
    background-color: #ccc;
    margin: 0;
}
  
.line-3-indicator:active {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 33.33%;
    background: #9a031E;
    transition: transform 0.3s ease;
}

.group-3-button{
    padding: 5px;
}

.btn-secondary{
    background-color: #9A031E;
}
  
@media screen and (max-width: 960px) {
    .group-3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:0;
        padding:1rem ;
    }

    .group-3 h2 {
      width: 300px;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-left: 2rem;
    }
    .group-3 h3{
        width:200px;
        font-size: 22px;
        font-weight: 600;
        line-height: 33px;
        text-align: left;
    }

    .group-3 .box-container{
        display: flex;
        flex-direction: column;
        margin-left:2rem;
    }

    .group-3 p{
        width: 250px;
        height:300px;
        font-size: 15px;
        font-weight: 300;
        line-height: 22.5px;
        text-align:left;
    }

    .box-container{
        margin:2rem;
    }
    
    .box-container img{
        width: 50px;
        padding: 3.5px 0px 0px 0px;
    }

    .group-3-control button{
        width: 80px;
        border-radius: 20px;
        padding: 5px 10px;
    }

    .group-3 .line-3-indicator:active {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width:50%;
        background: #9a031e;
        transition: transform 0.3s ease;
    }

    .line-3{
        width: 90%;
    }

    .line-2{
        margin-left: 2rem;
    }


    .group-3-control button{
        display: show;
        margin-bottom: 15px;
    }
}
  