.group-5{
    background-color:#FFFBF1;
    padding: 2rem;
   
}
  
.group-5 h1, .group-5 .line-4{
    margin-left:6rem;
    margin-bottom: 15px;
    
}
.group-5 h1{
    margin-bottom: 3rem;
    font-family: 'Telegraf', sans-serif;
    font-weight: 800;
    font-size: 35px;
    line-height: 30px;
}


  
.group-5-video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media screen and (max-width: 767px){
   .group-5{
    width: 100%;
   }
   
    .group-5 .line-4, .group-5 h1, .group-5 h3{
        margin-left:1rem;
    }
    .group-5 h1{
        margin-top: 6px;
        font-size: 30px;
    }


    .group-5-video .react-player {
        width: 100% !important;
        height: 300px !important;
    }
}