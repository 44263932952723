.navbar {
  background-color: #FFFBF1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  z-index: 10;
}

.navbar-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 18px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
}

.nav-item {
  display: flex;
  color: #0000;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: black;
  text-decoration: none;
  font-size: 15px;
  padding: 1rem 0.5rem;
}

.nav-links:hover {
  background-color:#E36414;
  color: #FFFFFF;
  padding: 30px 15px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.btn-primary{
  background-color:#0F4C5C ;
  padding: 5px 15px;
  outline: none;
  margin-right: 15px;
  border: none;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  background-color: #0F4C5C;
  text-decoration: none;
} 
.nav-button li{
  list-style: none;
}

@media screen and (max-width:960px) {
  .NavbarItems {
    position: relative;
    z-index: 1;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    transition: all 0.5s ease;
    background-color: #e6dddd;
    color: #fff;
    z-index: 1;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    cursor: pointer;
  }

  .nav-links li :hover {
    background-color: #c25715;
    color: #FFFFFF;
    padding: 30px 15px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    margin-left: -1rem;
    margin-top: -1rem;
  }

  nav .nav-item:hover{
    background-color: #c25715;
    color: #FFFFFF;
    padding: 30px 15px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #0a0a0a;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  .nav-button {
    display: none;
  }
}


