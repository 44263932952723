@import url('https://fonts.cdnfonts.com/css/base-neue');
@import url('https://fonts.cdnfonts.com/css/karla');
@import url('https://fonts.cdnfonts.com/css/telegraf');
                


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Roboto Medium', sans-serif;
  font-weight: 300;

}

:root {
  --backgroundcolor: #FFFBF1;
  --primarycolor: #5F0F40;
  --btnColor: #9A031E;
  --textcolor: #FB8B24;
  --secondary: #E36414;
  --text: #0F4C5C;
}


.home,
.real-estate,
.products,
.sign-up,
.marketing,
.consulting {
  max-width: 1500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center
 
}

.home {
  background-color: #FFFBF1;
}

.real-estate {
  display: flex;
  flex-direction: column;
}

.realEstate {
  padding: 3rem 4px;
  text-align: center;
  line-height: 3rem;
}

.realEstate h1 {
  font-family: 'Telegraf', sans-serif;
  font-weight: 700;
}

.realEstate-content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
  gap: 2rem;
  padding: 2rem;
}
.realEstate-info{
  background-color: #9A031E;
  color: white;
  height: 250px;
  width:600px;
  padding: 1.5rem;
  margin-top:6rem;
  margin-bottom: 1rem;
  text-align: justify;
}

.realEstate-Images {
  display: block;
  width:600px;
  height: 500px;
  object-fit: cover;
  overflow: hidden;
}

.slider-image {
  width: 600px;
  height: 500px;
  object-fit:contain;
  border-radius: 10px;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}



.Agric{
  background: #FFFBF1;

}
.agriculture {
  margin: 2rem;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
}

#heading-content{
  position: relative;
  background: url('../src/components/logo/agricback.jpg') no-repeat center center;
  background-size: cover;
}

#heading-content::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(1px);
  z-index: 1;

}
.heading-content{
  position: relative;
  z-index: 2;
  padding:2rem 10rem;
  line-height: 2rem;
}
.tag-1{
  padding: 5rem 8rem;
  text-align: center;

}
.tag-1 h1{
  font-family: 'Telegraf', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  padding-bottom: 20px;
}

.tag-1 h2{
  font-family: 'Roboto ', sans-serif;
  font-weight: 500;
  font-weight: 15px;
}

.tag-2 h3{
  font-family: 'Roboto Light', sans-serif;
  font-weight: 800;
  color: whitesmoke;
  text-align: center;
}
.agri-content{
  display: grid;
  grid-template-rows: 1fr 2fr;
  gap: 1rem;
}

.agriInfo-1 p, .agriInfo-2 p{
   padding-top: 10px;
}

.agriImages {
  display: block;
  width:500px;
  height: 500px;
  object-fit: cover;
  flex: 1;
  overflow: hidden;
  margin-top: 5rem;
  
}


.agriInfo-1, .agriInfo-2 {
  color: white;
  width: 100%;
  max-width: 900px;
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: justify;
}


.agriInfo-2{
  background-color: #9bce0e;
}
.agriInfo-1{
  background-color: #063a0f;
}

.agri-content h1{
  font-family: 'Telegraf', sans-serif;
  font-weight: 700;
  font-size: 25px;
  padding-bottom: 3rem;
}

.agri-content p{
  font-family: 'Roboto Light', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: justify;
}

.about{
  background-color: #FFFBF1;
}

.first-content{
  display: flex;
  flex-direction: row;
  padding:3rem 2rem;
  gap:3rem;
}


.about-content{
  margin:4rem 0;
}

.about-img img{
  width: 450px;
  height: auto;
  margin-top: 2rem;
}

.about-content h1{
  font-family: "Telegref" sans-serif;
  width: 595px;
  font-size:35px;
  font-weight: 800;
  color: #9A031E;
  margin-bottom:10px;
}
.about-content h2{
  font-size: medium;
  text-align:justify;
}
.about-info{
  margin-top: 2rem;
}

.about-info p{
  font-family: 'Roboto Light', sans-serif;
  font-weight: 300;
  text-align:justify;
  font-size: 18px;
  line-height: 30px;
  margin-top: 1rem;
}




@media screen and (max-width: 960px) { 
  .Content {
    width:100%;
    justify-self: center;
  }
  
  .realEstate h3{
    line-height: 20px;

  }
  .realEstate h1{
    line-height: 30px;
    padding-bottom: 15px;
  }
 
  .agriculture {
    display:flex;
    flex-direction: column;
    padding:10px;
    gap: 20px;
  }

  .first-content{
    display: flex;
    flex-direction:column;
    padding: 2rem;
    gap: 2rem;
    text-align: center;
    justify-items: center;
    justify-content: center;
  }
  
  .agriImages{
    margin-top: 0;
  }

  .about{
    background-color: #FFFBF1;
    padding: 0;
  }  
  
  .about-content{
    margin-top: 5rem;
    padding: 0;
  }

  .about-img img{
    width: 300px;
    height: auto;
  }
  
  .about-content h1{
    width:auto;
    text-align:center;
    font-size:25px;
  }
  
  .about-content h2{
    font-size: 15px;
    text-align: center;
  }

  .about-info {
    line-height: 20px;
    margin-top: 12px;
    padding:1rem 1rem 0 1rem;
  }
  .about-info p{
    text-align:justify;
    font-size: 15px;
    padding-right: 0;
  }

  .heading-content{
    padding: 1rem;
    font-size: 15px;
    line-height: 2rem;
  }
  .tag-1{
    padding:2rem 1rem;
  }
  .tag-1 h1{
    font-size: 32px;
    line-height: 35px;
    padding-bottom: 20px;
  }
  
  .tag-1 h2{
    font-weight: 6px;
    line-height: 25px;
  }
 

}