.group-6 {
  display: flex;
  flex-direction: column;
  background: #d9d9d9;
  padding: 4rem 1rem ;
  width: 100%;
}

.group-6 .line-4, .group-6 h1{
  margin-left:5rem;
}

.group-6 h1{
  font-family: 'Telegraf', sans-serif;
  margin-top: 10px;
  font-weight: 800;
}

.box {
  background-color: #ffffff;
  width:300px;
  height: 300px;
  padding:20px;
  margin: 3rem;
}

.slick-center .box{
  background-color: #9a031e;
  color: #ffffff;
}

.box p {
  width:250px;
  font-family: 'Roboto Light', sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 24.38px;
  text-align:justify;

}

.ref-contact p{
  margin-top:20px;
  font-family: 'Telegraf', sans-serif;
  font-weight: 700;
}

.group-6-line {
  position: relative;
  width: 100%;
  margin-top:2rem;
}

.line-3 {
  background: gray;
  height: 4px;
  width: 100%;
  border: none;
}

.line-3-indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 33.33%;
  background: #9a031e;
  transition: transform 0.3s ease;
}

.control .previous button,
.control .next button {
  background-color:transparent;
  padding: 5px 15px;
  border: 2px solid black;
  border-radius: 20px;
  width: 112px;
  height: 40px;
  color: black;
  margin: 0 10px;
  cursor: pointer;
}

.control .previous button:active,
.control .next button:active {
  color:#ffffff;
  background-color: black;
}

.slick-next.slick-arrow-hidden {
  display: none !important;
}


@media screen and (max-width: 767px){  
  .group-6{
    width: 100%;
    height: auto;
    padding:0;
    margin:10px 0;
  }

  .head-text{
    margin-top: 3rem;
  }

  .group-6 .line-4, .group-6 h1{
    margin-left:3rem;
  }

  .group-6 h1{
    font-size: 20px;
  }

  .box{
    width:180px;
    height: 200px;
    padding:8px;
    margin:10px;
    overflow: hidden;
  }

  .group-3-control .previous button,
  .group-3-control .next button{
    padding: 3px 12px;

  }

  .box p {
    width: 160px;  
    font-size: 11px;
    font-weight: 600;
    line-height: 13.41px;
    text-align: left;
    margin: 5px;
  }


  .ref-contact p{
    font-size: 11px;
    font-weight: 600;
    line-height: 13.41px;
    text-align: left;
    width: 100px;
  }   

}
