.group-4{
    background-color:#FFFBF1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:3rem  8rem 8rem;
    gap: 6px;
}
  
.group-4 h2{
    width: 513px;
    font-family: 'Telegraf', sans-serif;
    font-size: 35px;
    font-weight: 800;
}
  
.group-4-content{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
  
  
.bold {
    margin: 1rem 0;
    font-family: 'Telegraf', sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    width: 350px;
    height: 60px; 
    color: #9A031E;
}
  
.small {
    font-family: 'Roboto Light', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 22.5px;
    text-align:justify;
    width: 400px;
}

  
.line-4 {
    width: 90px;
    height: 0px;
    border: 4px solid #9A031E;
}
  
.line-5{
    margin-top: 2rem;
    background:gray;
    border:none;
    width: 400px;
}
  
.line-5:before{
    content: '';
    display: block;
    border-bottom: 4px solid var(--btnColor);
    max-width: 50%;
}
  
.group-4 .line-6{
    border-left: 4px solid #9A031E;
    height:70px;
    margin-top:2rem;
}
  
.track{
    display: flex;
    flex-direction:row;
    margin-top:2rem;
    gap:5px;
}
  
.track p{
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
}
 

  
.track h1{
    font-size:38px;
    font-weight: 700;
    line-height: 90px;
    text-align: center;
    color: #9A031E;
}


.group-4-img img{
    width: 500px;
    height: auto;
    
}


@media screen and (max-width:767px){
    .group-4{
        width: 100%;
        padding:2rem ;
    }

    .group-4 h2{
        width: 300px;
        height: 60px;
        font-size: 20px;
        line-height: 30px;
    }
    .group-4-content{
        display:flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .line-5{
        width:87%;
        margin-top: 6rem;
    }

    .bold{
        width: 290px;
        height: 46px;
        font-size: 16px;
        line-height: 24px;
    }
    .small{
        width: 290px;
        height: 53px;
        font-size: 15px;
        line-height: 22.5px;
    
    }

    .land p{
        width:40px;
        font-size: 12px;
        line-height: 24px;
    }
    .t-p p{
        width: 40px;
        font-size: 12px;
        line-height: 24px;
    }
    .b-d p{
        width:60px;
        font-size: 12px;
        line-height: 24px;
    }
    .track{
        gap: 0;
    }

    .track h1{
        width: 100px;
        font-size: 35px;
        line-height: 60px;
        margin: 0;
        align-items: center;
    }

    .group-4 .line-6{
        height:60px;
        margin-top:2rem;
        margin-left: -10px;
        margin-right: 10px;
    }
    
    .group-4-img img{
        width: 280px;
        height: auto;
        margin-left:0
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .group-4-img img{
        width: 300px;
        height: auto;
        
    }


}