:root {
  --btn: #0F4C5C;
}

.btn {
  padding: 5px 15px;
  outline: none;
  margin-right: 15px;
  border: none;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  font-family: 'Telegraf', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  background-color: #0F4C5C;
  text-decoration: none;
}

.btn :hover {
  padding: 6px 18px;
  background-color: transparent;
  color:var(--primary);
  border: 2px solid var(--primary);
  transition: all 5s ease-out;
}
